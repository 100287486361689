(function(exports) {
  "use strict";

  // Autogenerated with `python manage.py generate_permissions --save`
  // ###########################################################################
  // doc ID                    1B8PaJDm0fq9IwelyMOvMwXq4hTk5q5Ib5JeH3tiP9cg
  // doc ui Sheet              1796928001
  // doc roles Sheet           1967231901
  // doc Latest Modifying User José Jorge Lorenzo Vila (jjlorenzo@gmail.com)
  // doc Version               92684
  // doc Modified Date in UTC  Y-m-d H:i:s
  // ###########################################################################

  exports.permissionsMap = {
    "CanCreateAppointment": ["appointments_modify"],
    "CanCreateClaim": ["claims_modify"],
    "CanCreateCommBroadcastToPractice": ["commbroadcasts_modify"],
    "CanCreateConsentForm": ["consent_forms_modify"],
    "CanCreateConsentFormTemplate": ["consent_form_templates_modify"],
    "CanCreateEncounter": ["calibrationinfos_modify", "encounter_modify", "encounter_view", "encounters_modify", "image_modify", "images_modify", "practice_view", "prescription_modify", "prescription_view", "prescriptions_modify", "templates_provider_modify", "treatment_modify", "treatments_modify"],
    "CanCreateFeeProfile": ["feeprofiles_modify"],
    "CanCreateFile": ["files_modify"],
    "CanCreateGLAccount": ["glaccounts_modify"],
    "CanCreateImage": ["images_modify"],
    "CanCreateInsurancePlan": ["feeprofiles_modify", "plans_modify"],
    "CanCreateInvoice": ["invoices_modify"],
    "CanCreateKiosk": ["kiosks_modify"],
    "CanCreateKioskUser": ["kioskusers_modify"],
    "CanCreatePatient": ["feeprofiles_modify", "maximum_modify", "patients_modify", "patientsend_modify", "plan_modify", "plans_modify"],
    "CanCreatePatientFile": ["patientfiles_modify"],
    "CanCreatePatientImage": ["patientimages_modify"],
    "CanCreatePrescriptionTemplate": ["prescription_templates_modify"],
    "CanCreateReferral": ["referrals_modify"],
    "CanCreateReferralMessage": ["referralmessages_modify"],
    "CanCreateReferralPractice": ["referralpractices_modify"],
    "CanCreateReferralUser": ["referralusers_modify"],
    "CanCreateReferralUserPracticeMembership": ["referraluserpracticememberships_modify"],
    "CanCreateReminder": ["reminders_modify"],
    "CanCreateReport": ["images_view", "reports_modify", "treatments_view"],
    "CanCreateReportFile": ["reportfiles_modify"],
    "CanCreateReportTemplate": ["reporttemplates_modify"],
    "CanCreateReviewRequest": ["reviewrequest_modify"],
    "CanCreateSensorCalibration": ["sensorcalibrations_modify"],
    "CanCreateSuperCode": ["supercodes_modify"],
    "CanCreateSupportPracticeTickets": ["supportpractice_modify"],
    "CanCreateSupportUserTickets": ["supportpracticeuser_modify"],
    "CanCreateTimeBlock": ["timeblocks_modify"],
    "CanCreateUser": ["accounts_modify"],
    "CanDeleteAppointment": ["appointment_modify"],
    "CanDeleteConsentForm": ["consent_form_modify"],
    "CanDeleteConsentFormTemplate": ["consent_form_template_modify"],
    "CanDeleteEncounter": ["encounter_modify"],
    "CanDeleteEncounterTemplate": ["template_modify"],
    "CanDeleteFeeProfile": ["feeprofile_modify"],
    "CanDeleteFile": ["file_modify"],
    "CanDeleteImage": ["image_modify"],
    "CanDeleteInsurancePlan": ["plan_modify"],
    "CanDeleteKiosk": ["kiosk_modify"],
    "CanDeleteKioskUser": ["kioskuser_modify"],
    "CanDeletePatient": ["patient_modify"],
    "CanDeletePatientFile": ["patientfile_modify"],
    "CanDeletePatientImage": ["patientimage_modify"],
    "CanDeletePrescriptionTemplate": ["prescription_template_modify"],
    "CanDeleteReferral": ["referral_modify"],
    "CanDeleteReferralPractice": ["referralpractice_modify"],
    "CanDeleteReferralUser": ["referraluser_modify"],
    "CanDeleteReferralUserPracticeMembership": ["referraluserpracticemembership_modify"],
    "CanDeleteReminder": ["reminder_modify"],
    "CanDeleteReport": ["report_modify"],
    "CanDeleteReportFile": ["reportfile_modify"],
    "CanDeleteReportTemplate": ["reporttemplate_modify"],
    "CanDeleteSensorCalibration": ["sensorcalibration_modify"],
    "CanDeleteSuperCode": ["supercode_modify"],
    "CanDeleteTimeBlock": ["timeblock_modify"],
    "CanDownloadConsentForm": ["consent_form_view"],
    "CanDownloadPatientFile": ["patientfile_view"],
    "CanDownloadPatientImage": ["patientimage_view"],
    "CanListAccount": ["accounts_view"],
    "CanListAnalytic": ["glaccountamount_analytic_view", "practice_view"],
    "CanListBilling": ["accounts_view", "ar_journal_entries_view", "ar_journal_entry_view", "feeprofile_view", "glaccount_view", "glaccountamount_view", "invoices_view", "journalentries_view", "patient_view", "practice_view", "treatments_view"],
    "CanListCalendar": ["accounts_view", "appointments_view", "locations_view", "timeblocks_view"],
    "CanListConsentForm": ["consent_forms_view"],
    "CanListConsentFormTemplate": ["consent_form_templates_view"],
    "CanListEncounter": ["encounters_view"],
    "CanListEncounterTemplate": ["templates_practice_view", "templates_provider_view"],
    "CanListFeeProfile": ["feeprofiles_view", "fees_view", "payers_view", "practice_view"],
    "CanListFile": ["files_view"],
    "CanListFiles": ["files_view"],
    "CanListGLAccount": ["glaccountamount_view", "glaccounts_view", "journalentries_view", "practice_view"],
    "CanListImage": ["images_view"],
    "CanListInsurance": ["ar_journal_entries_view", "claims_view", "glaccount_view", "locations_view", "patient_view", "practice_view"],
    "CanListInsurancePayersInNetworkProvider": ["accounts_view", "payers_view"],
    "CanListInsurancePlan": ["payers_view", "plans_view"],
    "CanListKiosk": ["kiosks_view"],
    "CanListKioskUser": ["kioskusers_view"],
    "CanListMedicalAlert": ["medicalalert_view"],
    "CanListMessageTemplate": ["text_templates_view"],
    "CanListPatient": ["patients_view"],
    "CanListPatientFile": ["patientfiles_view"],
    "CanListPatientImage": ["patientimages_view"],
    "CanListPrescription": ["prescriptions_view"],
    "CanListPrescriptionTemplate": ["prescription_templates_view"],
    "CanListProcedure": ["procedures_view"],
    "CanListReferral": ["referrals_view"],
    "CanListReferralMessage": ["referralmessages_view"],
    "CanListReferralPractice": ["referralpractices_view"],
    "CanListReferralUser": ["referralusers_view"],
    "CanListReferralUserPracticeMembership": ["referraluserpracticememberships_view"],
    "CanListReminder": ["reminders_view"],
    "CanListReport": ["reports_view"],
    "CanListReportFile": ["reportfiles_view"],
    "CanListReportTemplate": ["reporttemplates_view"],
    "CanListReview": ["reviews_view"],
    "CanListReviewByReferral": ["reviewsbyreferral_view"],
    "CanListSensorCalibration": ["sensorcalibrations_view"],
    "CanListSuperCode": ["supercodes_view"],
    "CanListSupportPracticeTickets": ["supportpractice_view"],
    "CanListSupportUserTickets": ["supportpracticeuser_view"],
    "CanListTreatment": ["accounts_view", "patient_view", "treatments_view"],
    "CanListUser": ["accounts_view", "practice_view"],
    "CanListUserActivity": ["practice_users_activity_view"],
    "CanRetrieveAppointment": ["accounts_view", "appointment_view", "patients_view"],
    "CanRetrieveBillingSetting": ["accounts_view", "practice_view"],
    "CanRetrieveClaim": ["ar_journal_entries_view", "claim_view", "claims_view", "location_view", "patient_view", "practice_view"],
    "CanRetrieveConsentForm": ["consent_form_view"],
    "CanRetrieveConsentFormTemplate": ["consent_form_template_view"],
    "CanRetrieveEncounter": ["accounts_view", "calibrationinfos_view", "eligibilities_view", "encounter_view", "encounters_view", "feeprofile_view", "fees_view", "image_view", "images_view", "locations_view", "patient_view", "practice_view", "prescription_templates_view", "prescription_view", "prescriptions_view", "treatments_view"],
    "CanRetrieveFeeProfile": ["feeprofile_view"],
    "CanRetrieveFile": ["file_view"],
    "CanRetrieveImage": ["calibrationinfos_view", "image_view"],
    "CanRetrieveInsurancePlan": ["deductibles_view", "feeprofiles_view", "maximums_view", "payers_view", "plan_view", "procedures_view"],
    "CanRetrieveInvoice": ["account_view", "glaccount_view", "invoice_view", "patient_view", "treatments_view"],
    "CanRetrieveKiosk": ["kiosk_view"],
    "CanRetrieveKioskUser": ["kioskuser_view"],
    "CanRetrieveLocationOperatory": ["locations_view"],
    "CanRetrieveMessageTemplate": ["text_template_view"],
    "CanRetrievePatient": ["feeprofile_view", "feeprofiles_view", "patient_view", "patientdeductibles_view", "patientmaximums_view", "payers_view", "plans_view", "text_template_view"],
    "CanRetrievePatientFile": ["patientfile_view"],
    "CanRetrievePatientImage": ["patientimage_view"],
    "CanRetrievePracticeData": ["practice_clinical_data_view"],
    "CanRetrievePracticeSetting": ["practice_view"],
    "CanRetrievePrescription": ["prescription_view"],
    "CanRetrievePrescriptionTemplate": ["prescription_template_view"],
    "CanRetrieveProcedure": ["procedure_view"],
    "CanRetrieveReferral": ["referral_view"],
    "CanRetrieveReferralMessage": ["referralmessage_view"],
    "CanRetrieveReferralPractice": ["referralpractice_view"],
    "CanRetrieveReferralUser": ["referraluser_view"],
    "CanRetrieveReferralUserPracticeMembership": ["referraluserpracticemembership_view"],
    "CanRetrieveReminder": ["reminder_view"],
    "CanRetrieveReport": ["report_view"],
    "CanRetrieveReportFile": ["reportfile_view"],
    "CanRetrieveReportTemplate": ["reporttemplate_view"],
    "CanRetrieveReview": ["review_view"],
    "CanRetrieveSensorCalibration": ["sensorcalibration_view"],
    "CanRetrieveSuperCode": ["supercode_view"],
    "CanRetrieveSupportPracticeTickets": ["supportpractice_view"],
    "CanRetrieveSupportUserTickets": ["supportpracticeuser_view"],
    "CanRetrieveTimeBlock": ["accounts_view", "timeblock_view"],
    "CanRetrieveTreatmentAgreement": ["accounts_view", "encounter_view", "feeprofile_view", "patient_view", "practice_view", "treatments_view"],
    "CanRetrieveUser": ["account_view", "roles_view"],
    "CanRetrieveWizard": ["practice_view"],
    "CanUpdateAppointment": ["appointment_modify"],
    "CanUpdateBilling": ["journalentries_modify"],
    "CanUpdateBillingSetting": ["practice_modify"],
    "CanUpdateClaim": ["claim_modify", "claimssubmissions_view"],
    "CanUpdateConsentForm": ["consent_form_modify"],
    "CanUpdateConsentFormTemplate": ["consent_form_template_modify"],
    "CanUpdateEncounter": ["calibrationinfos_modify", "encounter_modify", "encounter_view", "image_modify", "images_modify", "patient_view", "practice_view", "prescription_modify", "prescription_view", "prescriptions_modify", "templates_provider_modify", "treatment_modify", "treatments_modify"],
    "CanUpdateExternalUser": [],
    "CanUpdateFeeProfile": ["fee_modify", "feeprofile_modify"],
    "CanUpdateFile": ["file_modify"],
    "CanUpdateGLAccount": ["glaccount_modify", "journalentries_modify"],
    "CanUpdateImage": ["calibrationinfos_modify", "image_modify"],
    "CanUpdateInsurance": ["journalentries_modify"],
    "CanUpdateInsurancePayersInNetworkProvider": ["account_modify"],
    "CanUpdateInsurancePlan": ["deductible_modify", "feeprofiles_modify", "maximum_modify", "plan_modify"],
    "CanUpdateKiosk": ["kiosk_modify"],
    "CanUpdateKioskUser": ["kioskuser_modify"],
    "CanUpdateLocationOperatory": ["location_modify"],
    "CanUpdateMedicalAlert": ["medicalalert_modify"],
    "CanUpdateMessageTemplate": ["text_template_modify"],
    "CanUpdatePatient": ["feeprofiles_modify", "maximum_modify", "patient_modify", "patientdeductible_modify", "patientmaximum_modify", "patientsend_modify", "plan_modify", "plans_modify"],
    "CanUpdatePatientFile": ["patientfile_modify"],
    "CanUpdatePatientImage": ["patientimage_modify"],
    "CanUpdatePracticeSetting": ["practice_modify"],
    "CanUpdatePrescriptionTemplate": ["prescription_template_modify"],
    "CanUpdateProcedure": ["procedure_modify"],
    "CanUpdateReferral": ["referral_modify"],
    "CanUpdateReferralMessage": ["referralmessage_modify"],
    "CanUpdateReferralPractice": ["referralpractice_modify"],
    "CanUpdateReferralUser": ["referraluser_modify"],
    "CanUpdateReferralUserPracticeMembership": ["referraluserpracticemembership_modify"],
    "CanUpdateReminder": ["reminder_modify"],
    "CanUpdateReport": ["images_view", "report_modify", "treatments_view"],
    "CanUpdateReportFile": ["reportfile_modify"],
    "CanUpdateReportTemplate": ["reporttemplate_modify"],
    "CanUpdateReview": ["review_modify"],
    "CanUpdateSensorCalibration": ["sensorcalibration_modify"],
    "CanUpdateSuperCode": ["supercode_modify"],
    "CanUpdateSupportUserTickets": ["supportpracticeuser_modify"],
    "CanUpdateTimeBlock": ["timeblock_modify"],
    "CanUpdateTreatmentAgreement": ["encounter_modify"],
    "CanUpdateUser": ["account_modify"],
    "CanUpdateWizard": ["practice_modify"],
    "CanHandlePatientContent": ["handle_patient_interaction", "handle_encounter_clinical", "handle_referral_report"]
  }

})(window);
